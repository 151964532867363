const links=[
    {
        name:"Home",
        path:"/"
    },
    {
        name:"Portfolios",
        path:"/portfolios"
    },
    {
        name:"About",
        path:"/about"
    },
    {
        name:"Contact",
        path:"/contact"
    },
];

export {links};