import React from "react";

function PortfolioHeading() {
    return (
        <div className="port-heading">
            <h1>Discover the works done by us!  </h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi, pariatur dolore officia nulla minus alias!</p>
        </div>
    );
};
export default PortfolioHeading;